const steps = [
  {
    title: "insight",
    description: [
      `During this phase, the focus will be about your work, your business, and your goals. By answering several questions regarding your business objectives, ideal customers,
       aesthetic preferences, etc, I will be able to gain a deeper understanding about both you
       and your business. This way, I can tailor the creation of your website to match your exact needs.`,
      `The purpose of this phase is to help me
            better understand your business so you are certain that you are in great hands.`,
      `Of course, to kick-start all of this, you will first have to get in touch with me! This way, we can discuss the service you will need,
            and the time and budget it will require to complete the project.`,
      `I feel that it is vital that I spend some time in understanding your work and business. This way, I can be confident that my product is one
            which best promotes and enhances you.`,
    ],
  },
  {
    title: "initial draft",
    description: [
      `Once all the research is complete, in this next step I will be creating the initial template for the website. This draft will usually be completed 1-2 business days
        after my research is complete.`,
      `For this first draft, I will not be demonstrating a completed website. Instead, I will be providing you an artboard of the potential result.
        From this, I will be looking forward to feedback from you in order to make adjustments and alterations where necessary.`,
    ],
  },
  {
    title: "revisions",
    description: [
      `During this phase, I will be making revisions to the initial draft based on your feedback. The revision process can be repeated multiple times until you are satisfied.
          The revisions done during this stage will focus primarily on the design of the product. A functionality focused revision will occur at a later stage.`,
    ],
  },
  {
    title: "application",
    description: [
      `The meat of the process. During this phase, I will be creating the product by applying and implementing the designs we've agreed upon. You can sit back,
        relax, and be confident that I will be going through the work to create for you the best product. Of course, I appreciate any additional changes or input you may have
         during this stage.`,
      `The length of time for this phase greatly varies depending on the number of functionalities you may require in the final product. Generally, a website
         for promotional and advertisement purposes would take 1 - 2 weeks.`,
    ],
  },
  {
    title: "final revisions",
    description: [
      `During this phase, I will be providing you with the completed product. Your feedback is appreciated to make sure everything is in place as you requested.
          The number of revisions during this phases would be limited according to our initial agreement. Revisions done during this phase would focus primarily on the functionality
          of the product. Of course, any changes necessary for the design would also be welcomed.`,
    ],
  },
  {
    title: "completion",
    description: [
      `During this last phase, I will be deploying your new website to the world! Once all outstanding invoices have been paid, I will be providing you with all the files and assets
          pertaining to the product. At this final stage, it's time to celebrate on the launch of our completed project!`,
    ],
  },
]

export default steps
