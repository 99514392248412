import React, { useState, useEffect } from "react"
import "./steps_page.css"
import useWindowDimensions from "../../APIs/window_dimensions"
import steps from "../../Markup/Steps"

const StepsPage = () => {
  const { height, width } = useWindowDimensions()
  const [isMobile, setIsMobile] = useState(true)

  useEffect(() => {
    if (height >= 720 && width >= 1280) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }
  }, [height, width])

  return (
    <div className="steps-page" id="steps-page">
      <section>
        <h2
          className="heading"
          style={{
            fontSize:
              36 + (70 - 36) * ((Math.min(1920, width) - 300) / (1000 - 300)),
            marginLeft: isMobile ? "2rem" : "",
          }}
        >
          THE PROCESS
        </h2>
        <div className="steps-box">
          {steps.map((step, index) => {
            return (
              <div key={`${index} container`} className="steps-container">
                <hgroup className="steps-number-container">
                  <div className="steps-number">{`0${index + 1}`}</div>
                  <div className="steps-title">{step.title}</div>
                </hgroup>
                <article className="steps-description">
                  {step.description.map((description, i) => (
                    <p key={i}>{description}</p>
                  ))}
                </article>
              </div>
            )
          })}
        </div>
      </section>
    </div>
  )
}

export default StepsPage
